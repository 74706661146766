.contact-us-container {
  background-color: white;
  width: 70%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 100px;
}

.contact-us-header {
  font-family: "Roboto-Bold";
  font-size: 28px;
  color: var(--black-text-color);
  margin-block-start: 0;
  margin-block-end: 60px;
}

.contact-us-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.contact-us-icon-circle {
  border: 2px solid var(--tertiary-color);
  padding: 8px;
  border-radius: 30px;
}

.contact-us-icon {
  color: var(--tertiary-color);
}

.contact-us-content {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.contact-us-name {
  font-family: "OpenSans-Bold";
  margin-bottom: 0px;
  margin-block-start: 8px;
  margin-block-end: 8px;
  color: var(--black-text-color);
}

.contact-us-description {
  font-family: "OpenSans";
  font-size: 14px;
  margin-block-start: 5px;
  margin-block-end: 0;
  color: var(--secondary-color);
  text-align: center;
}

@media only screen and (max-width: 1110px) {
  .contact-us-container {
    width: 90%;
  }
}

@media only screen and (max-width: 510px) {
  .contact-us-container {
    width: 90%;
  }
  .contact-us-content-container {
    flex-direction: column;
  }
  .contact-us-content {
    width: 100%;
    margin-top: 40px;
  }
  .contact-us-header {
    margin-block-end: 20px;
  }
}
