.our-apps-container {
  background-color: var(--primary-color);
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 100px;
}

.our-apps-header {
  font-family: "Roboto-Bold";
  font-size: 28px;
  color: white;
  margin-block-start: 0;
  margin-block-end: 60px;
}

.our-apps-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.our-apps-content {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 30px;
  height: 295px;
}

.our-apps-content-left {
  margin-right: 20px;
}

.our-apps-content-right {
  margin-left: 20px;
}

.our-apps-picture {
  height: 200px;
  margin-bottom: 10px;
  width: auto;
}

.our-apps-name {
  font-family: "OpenSans-Bold";
  margin-bottom: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: var(--black-text-color);
}

.our-apps-bio {
  font-family: "OpenSans";
  font-size: 14px;
  margin-block-start: 5px;
  margin-block-end: 0;
  color: var(--secondary-color);
}

.our-apps-coming-soon {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

@media only screen and (max-width: 636px) {
  .our-apps-content-container {
    flex-direction: column;
  }
  .our-apps-content-left {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .our-apps-content-right {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 300px) {
  .our-apps-content {
    width: 175px;
  }
}
