.introduction-section {
  max-width: 1500px;
  width: 70%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
}

.introduction-left {
  flex: 0.6;
}
.introduction-right {
  max-width: 40%;
  flex: 0.4;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}

.introduction-header {
  font-size: 50px;
  font-family: "Roboto-Bold";
  color: var(--black-text-color);
}

.introduction-bio {
  font-size: 20px;
  font-family: "OpenSans";
  padding-right: 200px;
  color: var(--secondary-color);
}

.introduction-logo {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1110px) {
  .introduction-right {
    display: none;
  }
  .introduction-left {
    flex: 1;
  }
  .introduction-section {
    width: 90%;
  }
  .introduction-bio {
    padding-right: 0px;
    text-align: center;
  }
  .introduction-header {
    text-align: center;
  }
}
