.nav-bar-container {
  height: 10vh;
  width: 100%;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo-container {
  height: 10vh;
  margin-left: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  height: 60%;
  width: auto;
}

.logo-text {
  color: white;
  margin-left: 15px;
  font-family: "Roboto-Bold";
  font-size: 28px;
}

.links {
  flex-direction: row;
  display: flex;
  height: 100%;
  align-items: center;
  width: 220px;
  justify-content: space-between;
  margin-right: 20%;
}

.link-text {
  color: white;
  font-size: 18px;
  font-family: "Roboto-Bold";
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .nav-bar-container {
    justify-content: center;
  }
  .logo-container {
    margin-left: 0;
  }
  .links {
    display: none;
  }
}
